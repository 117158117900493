import Cookies from "js-cookie";
import { encrypt } from "./crypto";
import { login, sign_up } from "../gtm";
import { constants } from "./constants";
import { updateBranch } from "../store/reducers/misc";
import { fetchCustomer } from "../store/reducers/customer";
import { getBranchDetails } from "../services/storeLocator";
import {
  setCartId,
  fetchCartItems,
  mergeCartsThunk,
  setCnCBranchThunk,
  setBillingAddressThunk,
  setShippingAddressThunk,
  fetchCartForLoggedInUser,
  fetchClickAndCollectStock,
} from "../store/reducers/cart";

const cookieExpireHours = (hours) => {
  const now = new Date();
  now.setTime(now.getTime() + hours * 60 * 60 * 1000);
  return {
    date: now,
    dateString: now.toUTCString(),
  };
};

const handleResponse = (res) => {
  const { payload, error } = res;
  if (error) {
    throw new Error(error.message);
  }
  return payload;
};

export const getSelectedBranchFromStorage = () => {
  let branch = null;
  try {
    const storedBranch = localStorage.getItem(
      constants.localStorage.selectedBranch,
    );
    if (storedBranch) {
      const parsedBranch = JSON.parse(storedBranch);
      branch = parsedBranch;
    }
  } catch (error) {
    //Branch Parsing Error Handling
    console.error(error.message);
  }
  return branch;
};

export const handleSignInProcess = async (
  { dispatch },
  signUpEvent = false,
) => {
  const guestCartId = localStorage.getItem(constants.localStorage.cartId);
  //Fetch Customer Details
  const custDetailsRes = await dispatch(fetchCustomer());
  const custDetails = handleResponse(custDetailsRes);

  // fetch cart id and email for local storage
  await dispatch(fetchCartForLoggedInUser());

  if (signUpEvent) sign_up(custDetails?.id);
  login(custDetails?.id);

  const selectedBranch = getSelectedBranchFromStorage();
  dispatch(updateBranch(selectedBranch));

  // get preferred branch of the user and set to local and cart if not any
  if (custDetails?.preferred_branch?.branch_id && !selectedBranch) {
    const detailsRes = await getBranchDetails({
      branch: custDetails?.preferred_branch?.hyperlink,
    });

    const [branchRes] = detailsRes;
    await dispatch(
      setCnCBranchThunk({ ...branchRes?.branch_info, setNew: false }),
    );
  }

  if (custDetails?.cart_id) {
    await dispatch(fetchCartItems());
  }

  if (!guestCartId) return;

  const defaultShippingId = custDetails?.default_shipping?.id;
  const defaultBillingId = custDetails?.default_billing?.id;

  //Merge and Fetch Cart Details
  const cartDetailsRes = await dispatch(
    mergeCartsThunk({
      guestCartId,
      getBranchInfo: true,
    }),
  );

  const cartDetails = handleResponse(cartDetailsRes);

  if (cartDetails?.cartId) {
    localStorage.setItem(constants.localStorage.cartId, cartDetails?.cartId);
    dispatch(setCartId(cartDetails?.cartId));
  }

  if (cartDetails?.branchId) {
    await dispatch(
      fetchClickAndCollectStock({ branch_id: cartDetails?.branchId }),
    );
  }

  if (
    !cartDetails?.branchId &&
    !cartDetails?.shippingAddresses?.length &&
    defaultShippingId
  ) {
    //If click and collect branch is not set; and
    //If customer has default shipping address set and no shipping address is
    // associated with his/her cart, then we need to manually set that.
    const cartShipAddrRes = await dispatch(
      setShippingAddressThunk({ addressId: defaultShippingId }),
    );
    handleResponse(cartShipAddrRes);
  }
  if (!cartDetails?.billingAddress && defaultBillingId) {
    //If no billing addr set. And user has a default billing addr.
    //Then set that as billing addr of cart;
    const cartBillAddrRes = await dispatch(
      setBillingAddressThunk({ addressId: defaultShippingId }),
    );
    handleResponse(cartBillAddrRes);
  }
};

export const handleSignInData = async (signInData) => {
  const customerToken = signInData.generateCustomerToken.token;
  const customerTokenCipher = await encrypt(customerToken);
  Cookies.set(constants.cookies.customerToken, customerTokenCipher, {
    expires: cookieExpireHours(constants.cookies.customerTokenExpiryHours).date,
    sameSite: "Lax",
    secure: true,
  });
  return { customerToken, customerTokenCipher };
};
