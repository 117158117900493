import { useEffect } from "react";
import { routes } from "@/lib/utils/routes";
import { messages } from "@/lib/utils/messages";
import { customerAxios } from "@/lib/utils/axios";
import { handleGraphqlResponse } from "@/lib/utils/functions";
import { handleClearBrowserStorage, handleLogout } from "@/lib/services/auth";

const logoutWhen = [
  "The current customer isn't authorized.",
  "The current user cannot perform operations on cart",
];

//Just to clear localStorage and Cookies
const storageMsg = ["The current customer has already logout."];

const useAxiosInterceptor = ({ router, dispatch }) => {
  useEffect(() => {
    const loadInterceptor = async () => {
      customerAxios.interceptors.response.use((axiosRes) => {
        const { data: apiRes } = axiosRes;
        try {
          handleGraphqlResponse(apiRes);
        } catch (err) {
          const logoutUser = logoutWhen.some((m) => err.message.includes(m));
          if (logoutUser) {
            apiRes.errors[0].message = messages.error.sessionExpired;
            handleLogout({
              dispatch,
              onSuccess() {
                router.push(routes.signin);
              },
            });
          }

          const clearStorage = storageMsg.some((m) => err.message.includes(m));
          if (clearStorage) {
            apiRes.errors[0].message = messages.error.sessionExpired;
            handleClearBrowserStorage({
              dispatch,
              onSuccess() {
                router.push(routes.signin);
              },
            });
          }
        }
        return axiosRes;
      });
    };
    loadInterceptor();
  }, [dispatch, router]);
};

export default useAxiosInterceptor;
