import "react-toastify/dist/ReactToastify.min.css";
import dynamic from "next/dynamic";
import { ToastContainer } from "react-toastify";
import Loader from "@/components/common/Loader";
import useAppInitialization from "./useAppInitialization";
import { createContext, useContext, useMemo } from "react";
const MiniTrolley = dynamic(
  () => import("@/components/common/shared/MiniTrolley"),
  {
    ssr: false,
  },
);

const AppInitializationContext = createContext();

export const useAppContext = () => {
  return useContext(AppInitializationContext);
};

export function AppInitialization({ children }) {
  const {
    data: { loading, routeLoading, appContextData },
    methods: { dispatch },
    components: { AppProgressBar },
  } = useAppInitialization();

  const ToastContainerMemo = useMemo(
    () => (
      <ToastContainer
        position="top-right"
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        style={{
          zIndex: 99999999999,
        }}
        className="custom-toastify"
      />
    ),
    [],
  );

  return (
    <AppInitializationContext.Provider value={appContextData}>
      {AppProgressBar()}
      {(routeLoading || loading > 0) && <Loader />}
      {ToastContainerMemo}
      <MiniTrolley dispatch={dispatch} />
      {children}
    </AppInitializationContext.Provider>
  );
}
